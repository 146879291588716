<template>
  <v-app>
    <v-card>
      <v-card-title class="headline">
        <v-icon @click="back()" size="30" style="color: black">mdi-arrow-left</v-icon>
        &nbsp; Manage Price for States
      </v-card-title>
      <v-container>
        <v-row >
          <v-col cols="12">
            <v-card>
              <h4 class="headline text-center">STATE PRICE FOR EXAMS</h4>
              <v-card-text>
                <v-container>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Exam Title *</label>
                    <div class="col-lg-8 col-xl-6">
                      <v-text-field v-model="exam.title" outlined dense readonly
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Default Price *</label>
                    <div class="col-lg-8 col-xl-6">
                      <v-text-field v-model="exam.default_price" outlined dense readonly
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-md-4 control-label" ></label>
                    <label class="col-md-2 control-label">Manage State Price</label>
                    <label class="col-md-2 control-label" v-if="exam.taken_as_group=='yes'">Additional Candidate Price</label>
                    <label class="col-md-2 control-label" v-if="exam.taken_as_group=='yes'">Min Candidate</label>
                    <label class="col-md-2 control-label" v-if="exam.taken_as_group=='yes'">Max Candidate</label>

                  </div>
                    <div class="form-group row" v-for="state in states">
                      <label class="col-xl-4 col-lg-4 col-form-label text-right">{{ state.name }}</label>
                      <v-col cols="12" sm="12" v-bind:md="(exam.taken_as_group=='yes') ? 2 : 9 ">
                        <v-text-field outlined dense v-model="state.state_price"  ></v-text-field>
                      </v-col>
                    <v-col cols="12" sm="12" md="2" v-if="exam.taken_as_group=='yes'">
                      <v-text-field outlined dense v-model="state.additional_candidate_prices" ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" v-if="exam.taken_as_group=='yes'">
                      <v-text-field outlined dense  v-model="state.min_candidates"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" v-if="exam.taken_as_group=='yes'">
                      <v-text-field outlined dense v-model="state.max_candidates"></v-text-field>
                    </v-col>
                  </div>

                  <div class="text-right">
                    <v-btn rounded color="primary" dark @click="savePrice()">
                        Save
                      </v-btn>
                    </div>

                </v-container>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>
<script>
import StateService from "@/services/setting/state/StateService";
import OnlineExaminationService from "@/services/product/examination/OnlineExaminationService";

const state = new StateService();
const examination=new OnlineExaminationService();
export default {
  data() {
    return {
      states:[],
      exam:{},
      spArr:[],
      cpArr:[],
      minArr:[],
      maxArr:[],
      exam_price:{}
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: "online-examination",
      });
    },
    getStates() {
      state
          .getAllState()
          .then((response) => {
            this.states = response.data;

          })
          .catch((error) => {

          })
    },
    examinationDetail(){
      examination
          .show(this.examinationId)
          .then(response => {
            this.exam=response.data.examination;
          });
    },
    getPrice() {
      examination
      .managePrice(this.examinationId)
          .then(response => {
            this.spArr=response.data.spArr;
            this.cpArr=response.data.cpArr;
            this.minArr=response.data.minArr;
            this.maxArr=response.data.maxArr;

            this.states=this.states.map(state=>{
            let data = {'id':state.id,'name':state.name,'state_price':"0.00",'additional_candidate_prices':"0.00","min_candidates":"0.00",'max_candidates':"0.00"};

            for (let key in this.spArr){
              if(key==state.id){
                  data.state_price=this.spArr[key];
                }
              }
            for (let key in this.cpArr){
              if(key==state.id){
                  data.additional_candidate_prices=this.cpArr[key];
                }
              }
              for (let key in this.minArr){
                if(key==state.id){
                  data.min_candidates=this.minArr[key];
                }
              }
              for (let key in this.maxArr){
                if(key==state.id){
                  data.max_candidates=this.maxArr[key];
                }
              }
              return data;
            });

          });
    },
    savePrice()
    {

      this.exam_price['state_price']=this.states;
      this.exam_price['default_price']=this.exam.default_price;
      examination
      .savePrice(this.examinationId,this.exam_price)
          .then(response => {
            this.$router.push({
              name: "online-examination",
            });
            this.$snotify.success("Price updated");
          });
    }
  },
  mounted(){
    this.getStates();
    this.examinationDetail();
    this.getPrice();


  },
  computed:{
    examinationId () {
      return this.$route.params.examinationId;
    },
    routeTab() {
      if(this.examinationId!=null || this.examinationId!=undefined) {
        return {
          title: `Manage Price of -${this.exam.title}`,
          tips: `Manage Price for ${this.exam.title}`
        }
      }
    }
  }
}
</script>